import React from 'react'

// const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const ClockIcon = ({width, height}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 87 87'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M43.5 5.4375C22.4807 5.4375 5.4375 22.4807 5.4375 43.5C5.4375 64.5193 22.4807 81.5625 43.5 81.5625C64.5193 81.5625 81.5625 64.5193 81.5625 43.5C81.5625 22.4807 64.5193 5.4375 43.5 5.4375ZM58.4956 55.1991L56.0657 58.5126C56.0129 58.5847 55.9463 58.6457 55.8698 58.6921C55.7933 58.7384 55.7084 58.7692 55.62 58.7826C55.5315 58.796 55.4413 58.7918 55.3545 58.7703C55.2677 58.7487 55.186 58.7103 55.1142 58.657L41.0616 48.4107C40.9741 48.3479 40.9029 48.2649 40.8541 48.1688C40.8053 48.0727 40.7803 47.9663 40.7812 47.8585V24.4688C40.7812 24.0949 41.0871 23.7891 41.4609 23.7891H45.5476C45.9214 23.7891 46.2272 24.0949 46.2272 24.4688V45.4966L58.3427 54.2561C58.6485 54.4685 58.7165 54.8933 58.4956 55.1991Z'
      fill='#30271C'
    />
  </svg>
)

export default ClockIcon

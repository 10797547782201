import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'

function FeaturedImages ({featuredImages}) {
  return (
    <FeaturedImagesWrapper>
      {featuredImages.images.map(image => (
        <img key={image.image.asset.url} src={image.image.asset.url} alt={image.alt} />
      ))}
    </FeaturedImagesWrapper>
  )
}

const FeaturedImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 100;
  margin-top: -225px;

  img {
    margin: 1rem;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.45);
  }

  @media ${theme.media.desktop} {
    margin-top: -140px;

    img {
      width: 225px;
    }
  }

  @media ${theme.media.tablet} {
    margin-top: -80px;

    img {
      width: 150px;
    }
  }

  @media ${theme.media.phoneLarge} {
    display: none;
  }
`

export default FeaturedImages

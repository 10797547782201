import React from 'react'

// const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const LocationIcon = ({width, height}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 29 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.9584 10.8751C22.9584 9.76432 22.7396 8.66443 22.3145 7.63822C21.8894 6.61201 21.2664 5.67957 20.481 4.89414C19.6955 4.10871 18.7631 3.48567 17.7369 3.0606C16.7107 2.63553 15.6108 2.41675 14.5 2.41675C13.3893 2.41675 12.2894 2.63553 11.2632 3.0606C10.2369 3.48567 9.3045 4.10871 8.51908 4.89414C7.73365 5.67957 7.11061 6.61201 6.68554 7.63822C6.26047 8.66443 6.04169 9.76432 6.04169 10.8751C6.04169 12.551 6.5371 14.1098 7.3769 15.4245H7.36723C10.2189 19.8892 14.5 26.5834 14.5 26.5834L21.6328 15.4245H21.6244C22.4948 14.0667 22.9578 12.4879 22.9584 10.8751ZM14.5 14.5001C13.5386 14.5001 12.6166 14.1182 11.9368 13.4383C11.2569 12.7585 10.875 11.8365 10.875 10.8751C10.875 9.91367 11.2569 8.99164 11.9368 8.31182C12.6166 7.632 13.5386 7.25008 14.5 7.25008C15.4614 7.25008 16.3835 7.632 17.0633 8.31182C17.7431 8.99164 18.125 9.91367 18.125 10.8751C18.125 11.8365 17.7431 12.7585 17.0633 13.4383C16.3835 14.1182 15.4614 14.5001 14.5 14.5001Z'
      fill='#C7A17A'
    />
  </svg>
)

export default LocationIcon

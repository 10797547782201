import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import PaperBorder from '../styles/PaperBorder'

function Tagline({tagline}) {
  return (
    <TaglineWrapper>
      <h2>{tagline.tagline}</h2>
      <PaperBorder />
      <img src={tagline.backgroundImage.asset.url} alt={tagline.alt} />
      <PaperBorder />
    </TaglineWrapper>
  )
}

const TaglineWrapper = styled.div`
  position: relative;
  width: 100%;

  h2 {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    font-size: 70px;
    font-weight: 200;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    color: ${theme.text.default};
    transform: translateX(-50%) translateY(-50%);

    @media ${theme.media.tablet} {
      font-size: 40px;
    }

    @media ${theme.media.phoneLarge} {
      font-size: 30px;
    }
  }

  img {
    margin: 0;
    width: 100%;
    filter: brightness(60%);

    @media ${theme.media.tablet} {
      min-height: 200px;
      object-fit: cover;
    }
  }

  svg {
    position: absolute;
    z-index: 100;

    &:first-of-type {
      top: -1px;
      transform: rotate(180deg);
    }

    &:last-of-type {
      bottom: -1px;

      path {
        fill: ${theme.bg.alt};
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
      display: none;
    }
  }
`

export default Tagline

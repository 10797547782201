import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import Logo from './Logo'
import Nav from './Nav'
import Order from './Order'

const Header = ({logo, navigation}) => (
  <HeaderWrapper>
    <Order />
    <Logo logo={logo} />
    <Nav navigation={navigation} />
  </HeaderWrapper>
)

const HeaderWrapper = styled.header`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  align-items: center;
  position: relative;
  z-index: 100;

  @media ${theme.media.tablet} {
    display: flex;
    flex-direction: column;
  }
`

export default Header

import React, {useState} from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import LocationIcon from './icon/location'
import PhoneIcon from './icon/phone'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function Contact ({contact}) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [form, setForm] = useState({'bot-field': '', name: '', email: '', message: ''})

  const handleSubmit = e => {
    setIsSubmitted(true)

    fetch('/', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({'form-name': 'contact', ...form})
    })

    e.preventDefault()
  }

  const handleChange = e => {
    e.persist()
    setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
  }

  return (
    <ContactWrapper id='Contact'>
      <h1>{contact.title}</h1>
      <ContactColumns>
        <div>
          <address>
            <div>
              <LocationIcon width={'25px'} height={'25px'} />
              <div>
                <p>{contact.name}</p>
                <p>{contact.address}</p>
              </div>
            </div>
            <div>
              <PhoneIcon width={'25px'} height={'20px'} />
              <p>{contact.phone}</p>
            </div>
          </address>
          {!isSubmitted ? (
            <Form
              name='contact'
              method='post'
              data-netlify='true'
              data-netlify-honeypot='bot-field'
              onSubmit={e => handleSubmit(e)}
            >
              <input type='hidden' name='form-name' value='contact' />
              <label
                css={`
                  display: none !important;
                `}
              >
                Don’t fill this out if you're human:{' '}
                <input name='bot-field' value={form['bot-field']} onChange={e => handleChange(e)} />
              </label>
              <div>
                <label htmlFor='name'>Name</label>
                <input
                  type='text'
                  name='name'
                  id='name'
                  required
                  value={form.name}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  name='email'
                  id='email'
                  required
                  value={form.email}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <label htmlFor='message'>Message</label>
                <textarea
                  name='message'
                  id='message'
                  value={form.message}
                  onChange={e => handleChange(e)}
                />
              </div>
              <div>
                <button type='submit'>Send</button>
              </div>
            </Form>
          ) : (
            <SuccessMessage>
              Thanks for sending a message. <br />
              We'll get back to you as soon as possible.
            </SuccessMessage>
          )}
        </div>
        <div>
          <GoogleMap
            title='Google Map'
            src={contact.googleMap}
            width='100%'
            height='400'
            frameBorder='0'
            style={{border: 0}}
            allowFullScreen
          />
        </div>
      </ContactColumns>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  margin: 8rem auto;

  h1 {
    margin-bottom: 5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: ${theme.text.secondary};

    &::after {
      content: "";
      display: block;
      margin: 1rem auto 0;
      width: 70px;
      height: 2px;
      background-color: ${theme.text.tertiary};
    }
  }

  @media ${theme.media.tablet} {
    margin: 4rem auto;

    h1 {
      margin-bottom: 3rem;
    }
  }
`

const ContactColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1000px;

  @media ${theme.media.tablet} {
    grid-template-columns: 1fr;
  }

  address {
    display: flex;
    flex-direction: column;
    font-style: normal;

    svg {
      margin-right: 1rem;
    }
  }

  address > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    color: ${theme.text.alt};
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  label {
    display: block;
    margin-bottom: 0.25rem;
    color: ${theme.text.alt};
  }

  input,
  textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 100%;
    color: ${theme.text.alt};
    background-color: ${theme.bg.alt};
    border: none;
  }

  textarea {
    height: 150px;
  }

  button {
    display: flex;
    margin-left: auto;
    padding: 5px 10px;
    cursor: pointer;
    background-color: ${theme.button.default};
    color: ${theme.text.default};
    font-size: 14px;
    text-transform: uppercase;
    border: none;
  }
`

const SuccessMessage = styled.p`
  color: ${theme.text.alt};
`

const GoogleMap = styled.iframe`
  min-height: 515px;
  margin-bottom: 0;
`

export default Contact

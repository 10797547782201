import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import PaperBorder from '../styles/PaperBorder'

function TaglineTwo({taglineTwo}) {
  return (
    <TaglineTwoWrapper>
      <h2>{taglineTwo.taglineTwo}</h2>
      <PaperBorder />
      <img src={taglineTwo.backgroundImage.asset.url} alt={taglineTwo.alt} />
      <PaperBorder />
    </TaglineTwoWrapper>
  )
}

const TaglineTwoWrapper = styled.div`
  position: relative;

  h2 {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    font-size: 70px;
    font-weight: 200;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    color: ${theme.text.default};
    transform: translateX(-50%) translateY(-50%);

    @media ${theme.media.tablet} {
      font-size: 40px;
    }

    @media ${theme.media.phoneLarge} {
      font-size: 30px;
    }
  }

  img {
    width: 100%;
    filter: brightness(60%);

    @media ${theme.media.tablet} {
      min-height: 200px;
      object-fit: cover;
    }
  }

  svg {
    position: absolute;
    z-index: 100;

    &:first-of-type {
      top: -1px;
      transform: rotate(180deg);

      path {
        fill: ${theme.bg.alt};
      }
    }

    &:last-of-type {
      bottom: -1px;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
      display: none;
    }
  }
`

export default TaglineTwo

import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
// import InstagramIcon from './icon/instagram'
import FacebookIcon from './icon/facebook'
import PaperBorder from '../styles/PaperBorder'

const Footer = ({site, contact}) => {
  return (
    <FooterWrapper>
      <PaperBorderWrapper>
        <PaperBorder />
      </PaperBorderWrapper>
      <SocialMedia>
        {/* <a href={siteSettings.instagram} alt='Instagram'>
        <InstagramIcon />
      </a> */}
        <a href={site.facebook} alt='Facebook'>
          <FacebookIcon width={'30px'} height={'30px'} />
        </a>
      </SocialMedia>
      <Name>{contact.name}</Name>
      <p>{contact.phone}</p>
      <p>{contact.address}</p>
      <Copyright>
        &copy; {new Date().getFullYear()}, Developed by{' '}
        <a
          css={`
            color: ${theme.text.alt};
          `}
          href='http://intuviosolutions.com'
          title='Intuvio Solutions'
        >
          Intuvio Solutions
        </a>
      </Copyright>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  background-color: ${theme.bg.alt};

  .paperBorder {
    position: absolute;
    z-index: 100;
    top: -1px;
    transform: rotate(180deg);
  }

  h1 {
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: ${theme.text.secondary};

    &::after {
      content: "";
      display: block;
      margin: 1rem auto 0;
      width: 70px;
      height: 2px;
      background-color: ${theme.text.tertiary};
    }
  }

  p {
    text-align: center;
    color: ${theme.text.alt};
  }
`

const PaperBorderWrapper = styled.div`
  width: 100%;

  svg {
    position: absolute;
    z-index: 100;
    top: -1px;
    transform: rotate(180deg);
  }
`

const SocialMedia = styled.div`
  margin: 2rem auto 1rem;
`

const Name = styled.p`
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const Copyright = styled.p`
  margin-top: 2rem;
  font-size: 12px;
`

export default Footer

import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'

function Nav ({navigation}) {
  return (
    <NavWrapper>
      <ul>
        {navigation.navigationItems.map(item => (
          <li key={item}>
            <a href={`#${item}`} alt={item}>
              {item}
            </a>
          </li>
        ))}
      </ul>
    </NavWrapper>
  )
}

const NavWrapper = styled.nav`
  margin-left: auto;
  margin-right: 2rem;

  ul {
    display: flex;
  }

  li {
    font-family: "Oswald";
  }

  a {
    padding: 0.75rem;
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.text.default};
  }

  @media ${theme.media.tablet} {
    margin: auto;
  }
`

export default Nav

import React from 'react'
import Header from './Header'

import '../styles/reset.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, logo, navigation}) => (
  <>
    <Header
      siteTitle={siteTitle}
      onHideNav={onHideNav}
      onShowNav={onShowNav}
      showNav={showNav}
      logo={logo}
      navigation={navigation}
    />
    <div>{children}</div>
  </>
)

export default Layout

export const theme = {
  bg: {
    default: '#FFFFFF',
    alt: '#EAE7DE'
  },
  text: {
    default: '#FFFFFF',
    alt: '#666666',
    secondary: '#C7A17A',
    tertiary: '#30271C'
  },
  button: {
    default: '#C7A17A'
  },
  media: {
    desktop: '(max-width: 1024px)',
    tablet: '(max-width: 768px)',
    phoneLarge: '(max-width: 600px)',
    phoneSmall: '(max-width: 320px)'
  }
}

export default theme

import React from 'react'

// const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const PhoneIcon = ({width, height}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M17.7497 14.9679L15.2119 17.4853C14.8373 17.8695 14.3408 17.9997 13.8572 18.0002C11.7186 17.9362 9.69717 16.8857 8.03745 15.807C5.31312 13.8251 2.81343 11.3675 1.24458 8.39772C0.64287 7.15238 -0.0631504 5.56341 0.0043046 4.17338C0.0103196 3.65048 0.15123 3.13728 0.519495 2.80023L3.05728 0.263717C3.58426 -0.184528 4.09389 -0.029548 4.43112 0.492572L6.47279 4.3641C6.68768 4.82276 6.56441 5.31419 6.2438 5.64192L5.30882 6.57642C5.2511 6.65549 5.21434 6.74549 5.21342 6.84344C5.57195 8.23127 6.65811 9.51042 7.61763 10.3907C8.57714 11.271 9.60851 12.4637 10.9473 12.746C11.1128 12.7922 11.3155 12.8084 11.4339 12.6984L12.5215 11.5922C12.8964 11.308 13.4378 11.1691 13.8381 11.4015H13.8572L17.5398 13.5756C18.0804 13.9145 18.1368 14.5694 17.7497 14.9679Z'
        fill='#C7A17A'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default PhoneIcon

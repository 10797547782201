import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

function Logo ({logo}) {
  return (
    <LogoWrapper>
      <Link to='/'>
        <img src={logo.logo.asset.url} alt={logo.alt} />
      </Link>
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  grid-column-start: 2;
  padding-top: 1rem;
`

export default Logo

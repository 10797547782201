import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import PaperBorder from '../styles/PaperBorder'

function Hero({hero}) {
  return (
    <HeroWrapper>
      <h1>{hero.tagline}</h1>
      <img src={hero.backgroundImage.asset.url} />
      <PaperBorder />
    </HeroWrapper>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  margin-top: -210px;

  h1 {
    position: absolute;
    z-index: 100;
    top: 40%;
    left: 50%;
    width: 100%;
    padding: 0 1rem;
    font-size: 80px;
    font-weight: 200;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    color: ${theme.text.default};
    transform: translateX(-50%) translateY(-40%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
  }

  svg {
    position: absolute;
    bottom: -1px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    svg {
      display: none;
    }
  }

  @media ${theme.media.desktop} {
    h1 {
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 50px;
    }
  }

  @media ${theme.media.tablet} {
    margin-top: -270px;
    height: 50vh;

    h1 {
      top: 70%;
      transform: translateX(-50%) translateY(-70%);
      font-size: 40px;
    }
  }

  @media ${theme.media.phoneLarge} {
    margin-top: -270px;
    height: 60vh;
  }

  @media ${theme.media.phoneSmall} {
    h1 {
      top: 80%;
      transform: translateX(-50%) translateY(-80%);
      font-size: 32px;
    }
  }
`

export default Hero

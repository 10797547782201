import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'

function Menu ({menu}) {
  return (
    <MenuWrapper id='Menu'>
      <MenuIntro>
        <h1>{menu.title}</h1>
        <p>{menu.description}</p>
      </MenuIntro>
      {menu.categories.map(category => (
        <MenuCategory key={category.title}>
          <h3>{category.title}</h3>
          <MenuItems>
            {category.menuItems.map(item => (
              <MenuItem key={item.title} highlight={item.highlight}>
                {/* <img src={item.image.asset.fluid.src} alt={item.title} /> */}
                <MenuItemDescription>
                  <div>
                    <h4>{item.title}</h4>
                    <h5>
                      {item.price
                        ? '$' +
                          item.price.toLocaleString('en', {
                            useGrouping: false,
                            minimumFractionDigits: 2
                          })
                        : ''}
                    </h5>
                  </div>
                  <p>{item.description}</p>
                </MenuItemDescription>
              </MenuItem>
            ))}
          </MenuItems>
        </MenuCategory>
      ))}
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0;
  background-color: ${theme.bg.alt};

  @media ${theme.media.tablet} {
    padding: 4rem 1rem;
  }
`

const MenuIntro = styled.div`
  h1 {
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: ${theme.text.secondary};

    &::after {
      content: "";
      display: block;
      margin: 1rem auto 0;
      width: 70px;
      height: 2px;
      background-color: ${theme.text.tertiary};
    }
  }

  p {
    text-align: center;
    color: ${theme.text.alt};
    max-width: 500px;
  }
`

const MenuCategory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;

  h3 {
    font-weight: 400;
    margin-bottom: 2rem;
  }
`

const MenuItems = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  max-width: 1000px;

  @media ${theme.media.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  max-width: 450px;
  padding: 0.5rem;
  background-color: ${props => (props.highlight ? 'white' : 'none')};

  img {
    margin: 0 1rem 0 0;
  }
`

const MenuItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div:first-of-type {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  h4 {
    margin-bottom: 1rem;
    font-weight: 400;
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    color: ${theme.text.secondary};
  }

  p {
    text-align: left;
    font-size: 14px;
  }
`

export default Menu

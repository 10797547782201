import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'
import ClockIcon from './icon/clock'

const Hours = ({hours}) => {
  return (
    <HoursWrapper id='Hours'>
      <ClockWrapper>
        <h2>Hours</h2>
        <ClockIcon width={'70px'} height={'70px'} />
      </ClockWrapper>
      <ul>
        {hours.hours.map(hour => (
          <TimePeriod key={hour.days}>
            <Days>{hour.days}</Days>
            <Time>{hour.hoursOpen}</Time>
          </TimePeriod>
        ))}
      </ul>
    </HoursWrapper>
  )
}

const HoursWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto;

  @media ${theme.media.tablet} {
    flex-direction: column;
  }
`

const ClockWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;

  h2 {
    margin-right: 1.25rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  @media ${theme.media.tablet} {
    margin: 0 0 2rem 0;
  }
`

const TimePeriod = styled.li`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

const Days = styled.span`
  color: ${theme.text.secondary};
  font-weight: 500;
`

const Time = styled.span`
  margin-left: 1rem;
  color: ${theme.text.alt};
  font-weight: 300;
`

export default Hours

import React from 'react'
import styled from 'styled-components'
import theme from '../styles/GlobalVariables'

const IntroParagraph = ({introParagraph}) => {
  return (
    <IntroParagraphWrapper>
      <h1>{introParagraph.title}</h1>
      <p>{introParagraph.description}</p>
    </IntroParagraphWrapper>
  )
}

const IntroParagraphWrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;

  h1 {
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: ${theme.text.secondary};

    &::after {
      content: "";
      display: block;
      margin: 1rem auto 0;
      width: 70px;
      height: 2px;
      background-color: ${theme.text.tertiary};
    }
  }

  p {
    text-align: center;
    color: ${theme.text.alt};
  }

  @media ${theme.media.tablet} {
    p {
      padding: 0 1rem;
    }
  }
`

export default IntroParagraph
